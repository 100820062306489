var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('row',[_c('col-12',[_c('details-table',{ref:"table1",attrs:{"fields":_vm.semenCollection.fields,"data":_vm.semenCollection.tableData},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('h5',{staticClass:"text-5 font-poppins-semibold fs-lg-2"},[_vm._v("Semen Collection")])]},proxy:true},{key:"ejaculations",fn:function(){return [_c('div',{staticClass:"py-2 px-3 bg-7"},[_c('div',{staticClass:"py-1 px-3 b-1 b-fourth round-1 text-fourth text-center"},[_vm._v("First")])]),_c('div',{staticClass:"py-2 px-3 bg-7"},[_c('div',{staticClass:"py-1 px-3 b-1 b-fourth round-1 text-fourth text-center"},[_vm._v("Second")])])]},proxy:true},{key:"v",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"py-2 bg-7"},[_c('div',{staticClass:"py-1 text-6"},[_vm._v(_vm._s(rowData.ejaculations.v.first))])]),_c('div',{staticClass:"py-2 bg-7"},[_c('div',{staticClass:"py-1 text-6"},[_vm._v(_vm._s(rowData.ejaculations.v.second))])])]}},{key:"im",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"py-2 bg-7"},[_c('div',{staticClass:"py-1 text-6"},[_vm._v(_vm._s(rowData.ejaculations.im.first))])]),_c('div',{staticClass:"py-2 bg-7"},[_c('div',{staticClass:"py-1 text-6"},[_vm._v(_vm._s(rowData.ejaculations.im.second))])])]}},{key:"t_perc",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"py-2 bg-7"},[_c('div',{staticClass:"py-1 text-6"},[_vm._v(_vm._s(rowData.ejaculations.t_perc.first))])]),_c('div',{staticClass:"py-2 bg-7"},[_c('div',{staticClass:"py-1 text-6"},[_vm._v(_vm._s(rowData.ejaculations.t_perc.second))])])]}},{key:"conic",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"py-2 bg-7"},[_c('div',{staticClass:"py-1 text-6"},[_vm._v(_vm._s(rowData.ejaculations.conic.first))])]),_c('div',{staticClass:"py-2 bg-7"},[_c('div',{staticClass:"py-1 text-6"},[_vm._v(_vm._s(rowData.ejaculations.conic.second))])])]}},{key:"remarks",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"py-2 bg-7"},[_c('div',{staticClass:"py-1 text-6"},[_vm._v(_vm._s(rowData.ejaculations.remarks.first))])]),_c('div',{staticClass:"py-2 bg-7"},[_c('div',{staticClass:"py-1 text-6"},[_vm._v(_vm._s(rowData.ejaculations.remarks.second))])])]}}])})],1),_c('col-12',{staticClass:"mt-4",attrs:{"lg":"6"}},[_c('details-table',{ref:"table2",attrs:{"fields":_vm.semenStorage.fields,"data":_vm.semenStorage.tableData},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('h5',{staticClass:"text-5 font-poppins-semibold fs-lg-2"},[_vm._v("Semen Storage")])]},proxy:true}])})],1),_c('col-12',{staticClass:"mt-4",attrs:{"lg":"6"}},[_c('details-table',{ref:"table3",attrs:{"fields":_vm.semenDisposal.fields,"data":_vm.semenDisposal.tableData},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('h5',{staticClass:"text-5 font-poppins-semibold fs-lg-2"},[_vm._v("Semen Disposal")])]},proxy:true}])})],1),_c('col-12',{staticClass:"mt-4"},[_c('details-table',{ref:"table4",attrs:{"fields":_vm.semenIssue.fields,"data":_vm.semenIssue.tableData},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('h5',{staticClass:"text-5 font-poppins-semibold fs-lg-2"},[_vm._v("Semen Issue")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }